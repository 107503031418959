module.exports = {
  siteTitle: 'Mythic Table', // <title>
  manifestName: 'SolidState',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'images/mythic_logo.png',
  pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Mythic Table',
  subHeading: 'The open virtual online tabletop',
  // social
  socialLinks: [
    {
      icon: 'fab fa-gitlab',
      name: 'GitLab',
      url: 'https://gitlab.com/mythicteam/mythictable/',
      displayUrl: 'Mythic Table on GitLab'
    },
    {
      icon: 'fab fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/mythictable',
      displayUrl: 'Mythic Table on Twitter'
    },
    {
      icon: 'fab fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/mythictable',
      displayUrl: 'Mythic Table on Facebook'
    },
    {
      icon: 'fab fa-reddit',
      name: 'Reddit',
      url: 'https://www.reddit.com/r/mythictable/',
      displayUrl: 'Mythic Table on Reddit'
    },
    {
      icon: 'fab fa-tumblr',
      name: 'Tumblr',
      url: 'https://mythictable.tumblr.com/',
      displayUrl: 'Mythic Table on Tumblr'
    },
    {
      icon: 'fab fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/c/mythictable',
      displayUrl: 'Mythic Table on Youtube'
    },
    {
      icon: 'fab fa-discord',
      name: 'Discord',
      url: 'https://discord.gg/c4bEWDQ',
      displayUrl: 'Mythic Table on Discord'
    },
    {
      icon: 'fas fa-envelope',
      name: 'Support',
      url: 'mailto:support@mythictable.com',
      displayUrl: 'Contact Us'
    },
  ],
};

import React from 'react';
import { Link } from 'gatsby';

export default function Nav({ onMenuToggle = () => { } }) {

  return (
    <nav
      id="menu"
      onClick={e => {
        onMenuToggle();
      }}
    >
      <div className="inner">
        <h2>Menu</h2>
        <ul className="links">
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="https://fp.mythictable.com/"
            >
              Play Now!
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/mission/"
            >
              Our Mission & Values
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/org/index/"
            >
              Our Philosophy
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/reports/index/"
            >
              Monthly Reports
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/features/"
            >
              Features
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/roadmap/"
            >
              Roadmap
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/team/mythicstories/index/"
            >
              Team Mythic Stories
            </Link>
          </li>
          <li>
            <Link
              onClick={e => {
                onMenuToggle();
              }}
              to="/faq/"
            >
              FAQ
            </Link>
          </li>
        </ul>
        <i
          className="close topright fas fa-times"
          onClick={() => onMenuToggle()}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onMenuToggle();
            }
          }}
          tabIndex='0'
          aria-label='Close Menu'
          role="button"
          href="#menu"
        >
        </i>
      </div>
    </nav >
  );
}

import React, { useEffect, useState } from 'react';
import Nav from './nav';
import Mail from './mailinglist.js'

import { Link } from 'gatsby';


export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);
  const [mailOpen, toggleMail] = useState(false);

  // Checks localStorage is user has visited site
  // if not, displays the subscribe to mailing list modal
  useEffect(() => {
    const isUsersFirstVisit = window.localStorage.getItem('firstVisit')
    if (!isUsersFirstVisit) {
      toggleMail(true)
      window.localStorage.setItem('firstVisit', 'false')
    }
  }, [])


  return (
    <>
      <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
        <h1>
          <Link to="/">Mythic Table</Link>
          &nbsp;
          <Link to="/land-ack">
            <span>
              <i className="fas fa-heart orange"></i>
            </span>
          </Link>
        </h1>

        {/* NAV - TOP RIGHT OF SCREEN*/}
        <nav>
          {/* PLAY NOW BUTTON */}
          <a
            href="https://fp.mythictable.com"
          >
            <span>Play Now!</span>
          </a>

          {/* MAIL */}
          <a
            href="#mail"
            onClick={e => {
              e.preventDefault();
              toggleMail(!mailOpen);
              toggleHeader(false)
            }}
            className="mailToggle"
            title='Join Our Mailing List'
          >
            <span>Join Us</span>
          </a>

          {/* MENU HAMBURGER */}
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              toggleHeader(!headerOpen);
              toggleMail(false)
            }}
            className="menuToggle"
          >
            <span>Menu</span>
            <i className='fas fa-bars' aria-label='menu'></i>
          </a>
        </nav>
      </header>

      {/* MENU MODAL - DISPLAYED */}
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
      {/* MAIL MODAL - DISPLAYED */}
      <div className={`${mailOpen ? 'is-menu-visible' : ' '}`}>
        <Mail onMailToggle={() => toggleMail(!mailOpen)} />
      </div>
    </>
  );
}

import React from 'react'



export default function Mail({ onMailToggle = () => { } }) {


    return (
        <nav
            id="menu"
        >
            <div className="inner">
                <h2>Join Our Community</h2>
                <div id="subscribe">
                    <form action="https://mythictable.us20.list-manage.com/subscribe/post?u=34d56cd42f708cf3153691d42&amp;id=82b7e379ae" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" rel="noopener noreferrer" noValidate>
                        <div id="mc_embed_signup_scroll">
                            <label htmlFor="mce-EMAIL-index">Sign up for our mailing list!</label>
                            <p>Stay up to date and get a glimpse behind the scenes of Mythic Table!</p>
                            <input type="email" name="EMAIL" id="mce-EMAIL-index" placeholder="Email address" required />
                            <input type="hidden" name="b_34d56cd42f708cf3153691d42_82b7e379ae" tabIndex="-1" value="" />
                            <div >
                                <br />
                                <input type="submit" value="Subscribe" id="mc-embedded-subscribe" />
                            </div>
                            <div >
                                <br />
                                <button
                                    type="button"
                                    onClick={e => {
                                        e.preventDefault()
                                        onMailToggle();
                                    }}
                                >
                                    No Thanks
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </nav>
    );
}




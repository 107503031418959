import React from 'react';
import config from '../../config';
import patreon from '../assets/images/patreon.png';

export default function Footer() {
  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Stay up to date</h2>
          <p>
            The best way to support Mythic Table and to stay up to date is
            to join our mailing list or to become a Patron.
          </p>
          <div></div>
          <form action="https://mythictable.us20.list-manage.com/subscribe/post?u=34d56cd42f708cf3153691d42&amp;id=82b7e379ae" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
            <div className="fields">
              <div className="field">
                <label htmlFor="mce-EMAIL-footer">Subscribe to the newsletter</label>
                <input type="email" name="EMAIL" id="mce-EMAIL-footer" placeholder="email address" required />
                <input type="hidden" name="b_34d56cd42f708cf3153691d42_82b7e379ae" tabIndex="-1" value="" />
              </div>
            </div>
            <ul className="contact"><li/><input type="submit" value="Subscribe" id="mc-embedded-subscribe" /></ul>
            
            <ul className="contact">
              <li/><a href="https://www.patreon.com/mythictable" data-patreon-widget-type="become-patron-button">
              <img src={patreon} alt="Become a Patron!" />
              </a>
            </ul>
          </form>
          
        <ul className="contact">

          {config.socialLinks.map(social => {
            const { icon, url, displayUrl } = social;
            return (
              <li key={url}>
                <i className={`${icon}`}></i>
                <a href={url}>{displayUrl ? displayUrl : url}</a>
              </li>
            );
          })}
        </ul>
        <ul className="copyright">
          <li>&copy; Mythic Table. All rights reserved.</li>
          <li>
            Design: <a href="https://github.com/anubhavsrivastava">Anubhav Srivastava</a> and <a href="http://html5up.net">HTML5 UP</a>
          </li>
          <li> 
            Please read our <a href="/privacy/">Privacy Policy</a>.
          </li>
          <li> 
            <a href="/sitemap">Site Map</a>.
          </li>
        </ul>
      </div>
    </section>
  );
}
